import { useState, useEffect } from "react";

export const useIsTableLoading = (isLoading, chart_data) => {
    const [isTableLoading, setIsTableLoading] = useState(true)
    useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            setIsTableLoading(isLoading)
        }
    }, [isLoading, chart_data])
    return [isTableLoading]
}

export const useChartTableData = (chart_data) => {
    const [chartTableData, setChartTableData] = useState([]);
    useEffect(() => {
        if (! chart_data?.table_data) {
            return;
        }
        setChartTableData(chart_data.table_data)
    }, [chart_data])
    return [chartTableData]
}