import React from 'react';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';


const paginationModel = { page: 0, pageSize: 10 };
const columns = [
    {
        field: 'date',
        headerName: 'Date',
        flex: 1, // Equal width
    },
    {
        field: 'volume',
        headerName: 'Wet Sales Volume (L)',
        type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'value',
        headerName: 'Wet Sales Value (R)',
        type: 'number',
        flex: 1, // Equal width
    },
];

function FuelSalesTable({ view_mode, chart_data, loading }) {
    const [chartTitle, setChartTitle] = React.useState('Sales Data');
    const [chartData, setChartData] = React.useState([]);

    const [chartLoading, setChartLoading] = React.useState(false);

    React.useEffect(() => {
        setChartLoading(loading)
    }, [loading]);

    // Manage Changes in view_mode
    React.useEffect(() => {
        if (view_mode === 'daily') {
            setChartTitle('Daily Sales - Aggregated');
        } else if (view_mode === 'weekly') {
            setChartTitle('Weekly Sales - Aggregated');
        } else if (view_mode ===  'monthly') {
            setChartTitle('Monthly Sales - Aggregated');
        }

        // setChartLoading(true)
    }, [view_mode]);

    // Manage Changes in chartData
    React.useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            // Choose the right data fields
            if (view_mode === 'daily') {
                setChartData(chart_data.daily_sales)
            }
            if (view_mode === 'weekly') {
                setChartData(chart_data.weekly_sales)
            }
            if (view_mode === 'monthly') {
                setChartData(chart_data.monthly_sales)
            }
        }

        // setChartLoading(false)
    }, [chart_data, chartTitle]);
    

    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    {chartTitle}
                </Typography>
                <Divider />
                <br/>
                {
                    !chartLoading ? (
                        <DataGrid
                            rows={chartData}
                            columns={columns}
                            getRowId={(row) => row.date}
                            initialState={{ pagination: { paginationModel } }}
                            pageSizeOptions={[5, 10]}
                            // checkboxSelection
                            // sx={{ border: 0 }}

                            sx={{
                                border: 0,
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: '#ff6600',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                },
                                '& .MuiDataGrid-row': {
                                    backgroundColor: '#ffffff',
                                    // '&:hover': {
                                    //     backgroundColor: '#ffa76d',
                                    // },
                                },
                            }}
                        />
                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5, // Customize height
                                    marginTop: 5,
                                    backgroundColor: '#ff6600', // Background track color
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black', // Bar color
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
}

export default FuelSalesTable;