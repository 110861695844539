import Typography from '@mui/material/Typography';
import { Divider } from "@mui/material"
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

import { useBillingChartData, useIsChartLoading } from '../hooks/useBillingAndCreditChart';
import { LoadingBox } from './Loading';

const BillingAndCreditChart = ({billingReport}) => {
    const [chartXLabels, dataSeries] = useBillingChartData(billingReport)
    const [isChartLoading] = useIsChartLoading(billingReport)

    return (
        <>{ !isChartLoading ?
            <>
                <Typography component="div" variant="h4" align="center">
                    Billing And Credit Chart
                </Typography>
                <Divider />
                <BarChart
                    height={400}
                    sx={{
                        marginTop:10,
                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                            transform: 'translate(-50px, 0)',
                        },
                    }}
                    series={dataSeries}
                    xAxis={[{ data: chartXLabels, scaleType: 'band', label: 'Date' }]}
                    yAxis={[{ label: 'Amount (R)' }]}
                />
            </> :
            <LoadingBox />
        }</>
    )
}

export default BillingAndCreditChart