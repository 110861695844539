import { gql } from "@apollo/client"

export const GET_BILLING_DATA = gql`
    query BillingReport($siteId: String!, $startDate: String!, $endDate: String!) {
    billingReport(siteId: $siteId, startDate: $startDate, endDate: $endDate) {
        table_data {
        site_id
        date
        sequence_id
        reference
        type
        amount
        balance
        }
    }
    }
`