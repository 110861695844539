import React from 'react';

import { styled } from '@mui/material/styles';

// Material Components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Custom Components
import FuelSalesChartRands from "./components/FuelSalesChartRands";
import FuelSalesChartLiters from "./components/FuelSalesChartLiters";
import FuelSalesTable from "./components/FuelSalesTable";

// GraphQL
import { GET_WET_SALES } from "./gql/query";
import { useQuery } from "@apollo/client";

const ToggleButtonStyled = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#ff6600',
        fontWeight: 'bold'
    }
});


function SalesPage({ selected_site }) {
    const [selectedSite, setSelectedSite] = React.useState('');
    const [wetSales, setWetSales] = React.useState({});
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);

    const [uiErrorMessage, setUiErrorMessage] = React.useState('');

    const today = new Date();
    const _30DaysAgo = new Date();
    _30DaysAgo.setDate(today.getDate() - 30)
    const [startDate, setStartDate] = React.useState( dayjs(_30DaysAgo.toISOString().split('T')[0]) );
    const [endDate, setEndDate] = React.useState( dayjs(today.toISOString().split('T')[0]) );

    const { loading, error, data: wetSalesGqlData } = useQuery(GET_WET_SALES, {
        variables: {
            siteId: selected_site,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
        },
        fetchPolicy: 'no-cache', // Disable caching for this query
        skip: (selected_site === 'Select Site')
    });
    
    React.useEffect(() => {
        setSelectedSite(selected_site)
    }, [selected_site])

    React.useEffect(() => {
        if (wetSalesGqlData !== undefined) {
            setWetSales(wetSalesGqlData.dailyWetSales)
        }
    }, [wetSalesGqlData])

    React.useEffect(() => {
        if (startDate.isAfter(endDate)) {
            setUiErrorMessage('The start-date cannot be after the end-date')
            setSnackBarOpen(true)
        }
    }, [startDate, endDate])

    const [chartMode, setChartMode] = React.useState('daily');
    const handleModeChange = (event, newChartMode) => {
        if (newChartMode !== null) {
            setChartMode(newChartMode);
        }
    };

    return (
        <div>
            {
                selectedSite !== 'Select Site' ? (
                    <Grid container spacing={3} sx={{ marginTop:15 }}>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <Box
                                        component="form"
                                        sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Start Date"
                                                value={startDate}
                                                onChange={(newDate) => {
                                                    setStartDate(newDate);
                                                }}
                                                format="YYYY-MM-DD" // Custom date format
                                                // renderInput={(params) => <TextField {...params} />}
                                            />
                                            <DatePicker
                                                label="End Date"
                                                value={endDate}
                                                onChange={(newDate) => {
                                                    setEndDate(newDate);
                                                }}
                                                format="YYYY-MM-DD" // Custom date format
                                                // renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        {/* <Button
                                            variant="contained"
                                            size='large'
                                            onClick={handleSubmitBtnClick}
                                            style={{ backgroundColor: '#ff6600', color: 'white' }}
                                        >
                                            Submit
                                        </Button> */}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid size={10} offset={{ sm: 2 }}>
                            <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <ToggleButtonGroup
                                        value={chartMode}
                                        exclusive
                                        onChange={handleModeChange}
                                        aria-label="Chart Mode"
                                    >
                                        <ToggleButtonStyled value="daily">Daily</ToggleButtonStyled>
                                        <ToggleButtonStyled value="weekly">Weekly</ToggleButtonStyled>
                                        <ToggleButtonStyled value="monthly">Monthly</ToggleButtonStyled>
                                    </ToggleButtonGroup>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <FuelSalesChartRands
                                view_mode={chartMode}
                                chart_data={wetSales}
                                loading={loading}
                            />
                        </Grid>

                        <Grid size={10} offset={{ sm: 2 }}>
                            <FuelSalesChartLiters
                                view_mode={chartMode}
                                chart_data={wetSales}
                                loading={loading}
                            />
                        </Grid>


                        {/* <Grid size={10} offset={{ sm: 2 }}>
                            <FuelSalesTable
                                view_mode={chartMode}
                                chart_data={wetSales}
                                loading={loading}
                            />
                        </Grid> */}
                    </Grid>
                ) : (
                    <Grid container spacing={8} sx={{ marginTop:15 }}>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <Box sx={{ width: '100%', height: '600px' }}>
                                <Typography component="div" variant="h4" align="center">
                                    Loading Sites...
                                </Typography>
                                <LinearProgress
                                    sx={{
                                        height: 5, // Customize height
                                        marginTop: 5,
                                        backgroundColor: '#ff6600', // Background track color
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'black', // Bar color
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
            
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default SalesPage;
