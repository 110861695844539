import React from 'react';

import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { BarChart } from '@mui/x-charts/BarChart';
import Divider from '@mui/material/Divider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import LinearProgress from '@mui/material/LinearProgress';
import { useChartData, useChartTitle, useDistinctProducts } from '../hooks/useSalesChartRands';

function FuelSalesChartRands({ view_mode, chart_data, loading }) {
    const [chartTitle] = useChartTitle('Sales Data', view_mode)
    const [chartXLabels, chartData] = useChartData(chart_data, view_mode)
    const [distinctProducts] = useDistinctProducts(chart_data)

    const [dataSeries, setDataSeries] = React.useState([]);
    const [totaSales, setTotaSales] = React.useState(0);

    const [chartLoading, setChartLoading] = React.useState(false);

    React.useEffect(() => {
        setChartLoading(loading)
    }, [loading]);


    // ---------------------------------------------------------
    // -------------- TRACK CHANGES IN CHART DATA --------------
    // ---------------------------------------------------------
    React.useEffect(() => {
        // set x-labels
        // setXLabels( chartData.map(item => { return item.date; }) )

        let totSales = 0;
        chartData.forEach(item => { totSales += item.value });
        totSales = Math.round( ( totSales + Number.EPSILON ) * 100 ) / 100;
        setTotaSales(totSales)

        // Prepare Data
        const tempDataSeries = [];
        let counter = 1;
        for (const product of distinctProducts) {
            const productPreakdowns = chartData.map(item => {
                const productBreakdown = item.product_breakdown;

                const matchingProductBreakdownItem = productBreakdown.filter(prodItem => { return prodItem.product_code === product; })[0];
                return matchingProductBreakdownItem;
            });

            const resultSeriesItem = {
                data: productPreakdowns.map(item => { return item.total_value; }),
                label: product,
                id: product,
                stack: 'total'
            };
            if (counter === 1) {
                resultSeriesItem.color = '#02B2AF';
            } else if (counter === 2) {
                resultSeriesItem.color = '#2E96FF';
            } else if (counter === 3) {
                resultSeriesItem.color = '#ff6600';
            } else if (counter === 4) {
                resultSeriesItem.color = '#000000';
            }
            tempDataSeries.push(resultSeriesItem);

            counter += 1;
        }

        setDataSeries(tempDataSeries);
        // setChartLoading(false)
    }, [chartData]);

    function formatNumber(value) {
        const val = Number(value / 1);
        return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(/\.00$/, '');
    }

    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea'}}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    {chartTitle} {chartLoading ? <span></span> : <><br/><span>R{formatNumber(totaSales)}</span></>}
                </Typography>
                <Divider />
                {
                    !chartLoading ? (
                        <BarChart
                            // width={700}
                            height={600}
                            sx={{
                                marginTop:10,
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    transform: 'translate(-50px, 0)',
                                },
                            }}
                            // series={[
                            //     { data: dieselData, label: 'DIESEL50', id: 'dieselId', stack: 'total' },
                            //     { data: ulp95Data, label: 'ULP50', id: 'ulp95Id', stack: 'total' },
                            //     { data: ulp93Data, label: 'ULP03', id: 'ulp93Id', stack: 'total' },
                            // ]}
                            series={dataSeries}
                            // xAxis={[{ data: xLabels, scaleType: 'band' }]}
                            xAxis={[{ data: chartXLabels, scaleType: 'band', label: 'Date' }]}
                            yAxis={[{ label: 'Amount (R)' }]}
                        />
                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5, // Customize height
                                    marginTop: 5,
                                    backgroundColor: '#ff6600', // Background track color
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black', // Bar color
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
}

export default FuelSalesChartRands;