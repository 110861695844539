import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

function OverviewPage({ selected_site }) {
    const [selectedSite, setSelectedSite] = React.useState('');

    React.useEffect(() => {
        setSelectedSite(selected_site)
    }, [selected_site])

    return (
        <div>
            {
                selectedSite !== 'Select Site' ? (
                    <Grid container spacing={3} sx={{ marginTop:15 }}>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <Typography component="div" variant="h4" align="center">
                                Overview Page
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={8} sx={{ marginTop:15 }}>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <Box sx={{ width: '100%', height: '600px' }}>
                                <Typography component="div" variant="h4" align="center">
                                    Loading Sites...
                                </Typography>
                                <LinearProgress
                                    sx={{
                                        height: 5, // Customize height
                                        marginTop: 5,
                                        backgroundColor: '#ff6600', // Background track color
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'black', // Bar color
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
        </div>
    );
};

export default OverviewPage;