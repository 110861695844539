import { gql } from "@apollo/client";

export const GET_CASH_DROP_DATA = gql`
    query cashDropReport($siteId: String!, $startDate: String!, $endDate: String!) {
        cashDropReport(siteId: $siteId, startDate: $startDate, endDate: $endDate) {
            date_range
            table_data {
                id
                site_id
                provider
                account_number
                safe_id
                date
                amount
                bag_number
                cashier
                customer_ref
            }
            daily {
                date
                amount
            }
            weekly {
                date
                amount
            }
            monthly {
                date
                amount
            }
        }
    }
`;