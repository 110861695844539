
export const formatDate = (date) => {
    const d = new Date(date)
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export const formatDateSast = (datetime) => {
    const [date, time, period] = datetime.split(' ')
    const d = formatDate(date)
    return `${d} ${time} ${period}`
}