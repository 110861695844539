import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { Divider } from "@mui/material"

import { useBillingData, useIsTableLoading } from '../hooks/useBillingAndCreditTable';
import { LoadingBox } from './Loading';

const paginationModel = { page: 0, pageSize: 10 };

const columns = [
    {
        field: 'date',
        headerName: 'Date',
        flex: 1
    },
    {
        field: 'sequence_id',
        headerName: 'Sequence Id',
        flex: 1
    },
    {
        field: 'reference',
        headerName: 'Reference',
        flex: 1
    },
    {
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
        flex: 1
    },
    {
        field: 'balance',
        headerName: 'Balance',
        type: 'number',
        flex: 1
    }
];

const BillingAndCreditTable = ({billingReport}) => {
    const [billingData] = useBillingData(billingReport)
    const [isTableLoading] = useIsTableLoading(billingReport)

    return (
        <>{ !isTableLoading ?
            <>
                <Typography component="div" variant="h4" align="center">
                    Billing And Credit Transactions
                </Typography>
                <Divider />
                <br />
                <DataGrid
                    rows={billingData}
                    columns={columns}
                    getRowId={(row) => row.index}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10, 25]}
                    sx={{
                        border: 0,
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: '#ff6600',
                            color: '#fff',
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-row': {
                            backgroundColor: '#ffffff',
                        },
                    }}
                />
            </> : 
            <LoadingBox />
        }</> 
    ) 
}

export default BillingAndCreditTable