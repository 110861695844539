import { gql } from "@apollo/client";

export const GET_PNL_DATA = gql`
    query pnlReport($siteId: String!, $startDate: String!, $endDate: String!) {
        pnlReport(siteId: $siteId, startDate: $startDate, endDate: $endDate) {
            date_range
            
            total_income
            total_expenses
            total_shorts

            table_data {
                income_lines {
                    description
                    amount
                }
                expenses_lines {
                    description
                    amount
                }
                shorts_lines {
                    description
                    amount
                }
            }

            chart_data {
                daily {
                    date
                    income_amount
                    expense_amount
                    short_amount
                }
                weekly {
                    date
                    income_amount
                    expense_amount
                    short_amount
                }
                monthly {
                    date
                    income_amount
                    expense_amount
                    short_amount
                }
            }
        }
    }
`;