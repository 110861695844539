// src/components/AuthMenu.js
import React from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import Dialog from "./Dialog";

function AuthMenu({ setAuthToken }) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogin = () => {
        // Implement login logic
        console.log('Login clicked');
        handleClose();

        setOpenDialog(true);
    };

    const handleLogout = () => {
        // Implement logout logic
        // console.log('Logout clicked');
        handleClose();

        // localStorage.setItem('token', null)
        localStorage.removeItem('token')
        
        setAuthToken(null)
        window.location.replace('/');
    };

    return (
        <>
            <IconButton
                size="large"
                aria-label="auth menu"
                aria-controls="auth-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{
                    background: '#ff6600'
                }}
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="auth-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleClose}
            >
                {/* Replace with actual authentication state */}
                {/* <MenuItem onClick={handleLogin}><Typography>Manage Users</Typography></MenuItem> */}
                <MenuItem onClick={handleLogout}><Typography>Sign Out</Typography></MenuItem>
            </Menu>


            <Dialog
                keepMounted
                open={openDialog}
                onClose={handleCloseDialog}
                value="Atria"
            />
        </>
    );
}

export default AuthMenu;
