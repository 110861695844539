import React from 'react';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';


const paginationModel = { page: 0, pageSize: 10 };
const columns = [
    {
        field: 'order_number',
        headerName: 'Order Number',
        flex: 1, // Equal width
    },
    {
        field: 'invoice_number',
        headerName: 'Invoice Number',
        // type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'delivery_date',
        headerName: 'Delivery Date',
        // type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'product',
        headerName: 'Product Code',
        // type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'net_unit_price',
        headerName: 'Net Unit Price (R/L)',
        type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'volume',
        headerName: 'Volume (L)',
        type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'value',
        headerName: 'Value (R)',
        type: 'number',
        flex: 1, // Equal width
    },
];

function FuelSalesTable({ chart_data, loading }) {
    const [chartTitle, setChartTitle] = React.useState('Upcoming Orders at The Time');
    const [chartData, setChartData] = React.useState([]);

    const [chartLoading, setChartLoading] = React.useState(false);

    React.useEffect(() => {
        setChartLoading(loading)
    }, [loading]);

    // Manage Changes in chartData
    React.useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            setChartData(chart_data.upcoming_invoices)
        }
    }, [chart_data]);
    

    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    {chartTitle}
                </Typography>
                <Divider />
                <br/>
                {
                    !chartLoading ? (
                        <DataGrid
                            rows={chartData}
                            columns={columns}
                            getRowId={(row) => row.id}
                            initialState={{ pagination: { paginationModel } }}
                            pageSizeOptions={[5, 10]}
                            // checkboxSelection
                            // sx={{ border: 0 }}

                            sx={{
                                border: 0,
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: '#ff6600',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                },
                                '& .MuiDataGrid-row': {
                                    backgroundColor: '#ffffff',
                                    // '&:hover': {
                                    //     backgroundColor: '#ffa76d',
                                    // },
                                },
                            }}
                        />
                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5, // Customize height
                                    marginTop: 5,
                                    backgroundColor: '#ff6600', // Background track color
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black', // Bar color
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
}

export default FuelSalesTable;