import React from 'react';

import { styled } from '@mui/material/styles';

import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// Material Components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LinearProgress from '@mui/material/LinearProgress';

// Custom Components
import TankLevelsChartByProduct from "./components/TankLevelsChartByProduct";
import TankLevelsChartByTank from "./components/TankLevelsChartByTank";
import LeftOverFuel from "./components/LeftOverFuel";
import TankLevelsOverTime from "./components/TankLevelsOverTime";
import FuelOrdersTable from "./components/FuelOrdersTable";

// GraphQL
import { GET_FUEL_INVENTORY } from "./gql/query";
import { useQuery } from "@apollo/client";
import { formatDateSast } from '../../functions/utils';

const ToggleButtonStyled = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#ff6600',
        fontWeight: 'bold'
    }
});


function InventoryPage({ selected_site }) {
    const [selectedSite, setSelectedSite] = React.useState('');
    const [fuelInventory, setFuelInventory] = React.useState({});

    // const [value, setValue] = React.useState(dayjs('2022-04-17T15:30'));

    const today = new Date();
    const [startDate, setStartDate] = React.useState( dayjs( today ) );
    const [timeStamp, setTimeStamp] = React.useState( '' );

    const { loading, error, data: fuelInventoryGqlData } = useQuery(GET_FUEL_INVENTORY, {
        variables: {
            siteId: selected_site,
            // dateTime: `${startDate.toISOString().split('T')[0]}T${timeStamp.toISOString().split('T')[1]}`,
            dateTime: startDate,
        },
        fetchPolicy: 'no-cache', // Disable caching for this query
        skip: (selected_site === 'Select Site')
    });
    
    React.useEffect(() => {
        setSelectedSite(selected_site)
    }, [selected_site])

    // React.useEffect(() => {
    //     console.log(timeStamp.toDate().toLocaleString())
    // }, [startDate])

    React.useEffect(() => {
        if (fuelInventoryGqlData !== undefined) {
            const tankLevelsOverTime = fuelInventoryGqlData.fuelInventory.tank_levels_over_time
                .map(val => {
                    val.time_sast = formatDateSast(val.time_sast)
                    return val
                })
            fuelInventoryGqlData.fuelInventory.tank_levels_over_time = tankLevelsOverTime
            setFuelInventory(fuelInventoryGqlData.fuelInventory)
            setTimeStamp(fuelInventoryGqlData.fuelInventory.timestamp)
        }
    }, [fuelInventoryGqlData])

    return (
        <div>
            {
                selectedSite !== 'Select Site' ? (
                    <Grid container spacing={3} sx={{ marginTop:15 }}>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    {/* <Typography component="h1" variant="h3">Fuel Inventory { timeStamp !== '' ? `( At ${timeStamp} )` : '' }</Typography> */}
                                    <Typography component="h1" variant="h3">Fuel Inventory 
                                        <Typography component="div" variant="h6">{ timeStamp !== '' ? `(At ${timeStamp})` : '' }</Typography>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid size={10} offset={{ sm: 2 }}>
                            <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <Box
                                        component="form"
                                        sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Date"
                                                value={startDate}
                                                onChange={(newDate) => {
                                                    setStartDate(newDate);
                                                }}
                                                format="YYYY-MM-DD" // Custom date format
                                                // renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* <TimePicker
                                                label="Time"
                                                value={startDate}
                                                onChange={(newValue) => setStartDate(newValue)}
                                                // onClose={handleTimePickerClose}
                                            /> */}
                                            {/* <TimePicker
                                                label="Time"
                                                value={timeStamp}
                                                onChange={(newValue) => setTimeStamp(newValue)}
                                            /> */}
                                        </LocalizationProvider>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>


                        {/* <Grid size={5}> */}
                        <Grid size={5} offset={{ sm: 2 }}>
                            <TankLevelsChartByTank
                                chart_data={fuelInventory}
                                loading={loading}
                            />
                        </Grid>
                        {/* <Grid size={10} offset={{ sm: 2 }}> */}
                        <Grid size={5}>
                            <TankLevelsChartByProduct
                                chart_data={fuelInventory}
                                loading={loading}
                            />
                        </Grid>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <TankLevelsOverTime
                                chart_data={fuelInventory}
                                loading={loading}
                            />
                        </Grid>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <LeftOverFuel
                                chart_data={fuelInventory}
                                loading={loading}
                            />
                        </Grid>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <FuelOrdersTable
                                chart_data={fuelInventory}
                                loading={loading}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={8} sx={{ marginTop:15 }}>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <Box sx={{ width: '100%', height: '600px' }}>
                                <Typography component="div" variant="h4" align="center">
                                    Loading Sites...
                                </Typography>
                                <LinearProgress
                                    sx={{
                                        height: 5, // Customize height
                                        marginTop: 5,
                                        backgroundColor: '#ff6600', // Background track color
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'black', // Bar color
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
            
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default InventoryPage;
