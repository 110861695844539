import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { BarChart } from '@mui/x-charts/BarChart';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { useChartTitle, useChartData, useDataSeries, useChartXLabels, useIsChartLoading } from '../hooks/useCashDropChart';

function FuelSalesChart({ view_mode, chart_data, isLoading }) {
    const [isChartLoading] = useIsChartLoading(isLoading, chart_data)
    const [chartTitle] = useChartTitle('Sales Data', view_mode)
    const [chartData] = useChartData(chart_data, view_mode, chartTitle)
    const [chartXLabels] = useChartXLabels(chartData)
    const [dataSeries] = useDataSeries(chartData)

    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea'}}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    {chartTitle}
                </Typography>
                <Divider />
                {
                    !isChartLoading ? (
                        <BarChart
                            height={400}
                            sx={{
                                marginTop:10,
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    transform: 'translate(-50px, 0)',
                                },
                            }}
                            series={dataSeries}
                            xAxis={[{ data: chartXLabels, scaleType: 'band', label: 'Date' }]}
                            yAxis={[{ label: 'Amount (R)' }]}
                        />
                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5, // Customize height
                                    marginTop: 5,
                                    backgroundColor: '#ff6600', // Background track color
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black', // Bar color
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
}

export default FuelSalesChart;