import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { LoadingSites } from '../../components/loading/Loading';
import BillingAndCreditTable from './components/BillingAndCreditTable';
import BillingAndCreditChart from './components/BillingAndCreditChart'
import { useQueryBillingReport } from './hooks/useBillingAndCreditTable';

function BillingAndCreditPage({ selected_site }) {
    const [{billingReport}] = useQueryBillingReport(selected_site)

    return (
        <div>
            {
                selected_site !== 'Select Site' ? (
                    <Grid container spacing={3} sx={{ marginTop:15 }}>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <BillingAndCreditChart billingReport={billingReport}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid size={10} offset={{ sm: 2 }}>
                            <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <BillingAndCreditTable billingReport={billingReport}/>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                ) : <LoadingSites />
            }
        </div>
    );
};

export default BillingAndCreditPage;