import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const LoadingBox = () =>
    <Box sx={{ width: '100%', height: '600px' }}>
        <LinearProgress
            sx={{
                height: 5,
                marginTop: 5,
                backgroundColor: '#ff6600',
                '& .MuiLinearProgress-bar': {
                    backgroundColor: 'black',
                },
            }}
        />
    </Box>