import { useState, useEffect } from "react";
import { useQuery } from '@apollo/client';
import { GET_BILLING_DATA } from '../gql/query';
import { useDate } from './useDate';

export const useQueryBillingReport = (selected_site) => {
    const [billingReport, setBillingData] = useState()
    const [{startDate, endDate}, {setStartDate, setEndDate}] = useDate()
    const { loading, error, data } = useQuery(GET_BILLING_DATA, {
        variables: {
            siteId: selected_site,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
        },
        fetchPolicy: 'no-cache',
        skip: (selected_site === 'Select Site' || startDate.isAfter(endDate))
    })
    useEffect(() => {        
        if (data !== undefined) {
            setBillingData(data.billingReport)
        }
    }, [data, startDate, endDate])
    return [{loading, error, billingReport}, {setStartDate, setEndDate}]
}

export const useIsTableLoading = (data) => {
    const [isTableLoading, setIsTableLoading] = useState(true)
    useEffect(() => {
        if (data !== undefined && data?.table_data !== undefined) {
            setIsTableLoading(false)
        }
    }, [data])
    return [isTableLoading]
}

export const useBillingData = (data) => {
    const [billingData, setBillingData] = useState([]);
    useEffect(() => {
        if (data !== undefined && data?.table_data !== undefined) {
            const formattedData = data.table_data.map((item, i) => {
                item.index = i
                return item
            })
            setBillingData(formattedData)
        }
    }, [data])
    return [billingData]
}