import { useState, useEffect } from "react"

export const useChartTitle = (title) => {
    const [chartTitle, setChartTitle] = useState(title)
    useEffect(() => {
        setChartTitle('Monthly Billing and Credit')
    }, [])
    return [chartTitle]
}

export const useBillingChartData = (billingReport) => {
    const [chartXLabels, setChartXLabels] = useState([]);
    const [dataSeries, setDataSeries] = useState([]);
    useEffect(() => {
        if (billingReport !== undefined && billingReport?.table_data !== undefined) {
            const formattedData = billingReport.table_data
                .map((item, i) => {
                    item.index = i
                    return item
                })
                .sort((a, b) => new Date(a.date) - new Date(b.date))

            const labels = formattedData.map(item => item.date)
            setChartXLabels(labels)

            const series = []
            series.push({
                data: formattedData.map(item => item.balance),
                label: "Total Billing and Credit Balance",
                id: "Total Billing and Credit Balance",
                color: '#ff6600'
            })
            setDataSeries(series)
        }
    }, [billingReport])
    return [chartXLabels, dataSeries]
}

export const useIsChartLoading = (data) => {
    const [isChartLoading, setIsChartLoading] = useState(true)
    useEffect(() => {
        if (data !== undefined && data?.table_data !== undefined) {
            setIsChartLoading(false)
        }
    }, [data])
    return [isChartLoading]
}