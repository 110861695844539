import React from 'react';

import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { BarChart } from '@mui/x-charts/BarChart';
import Divider from '@mui/material/Divider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import LinearProgress from '@mui/material/LinearProgress';

function FuelSalesChartLiters({ view_mode, chart_data, loading }) {
    const [chartTitle, setChartTitle] = React.useState('Sales Data');
    const [chartData, setChartData] = React.useState([]);
    const [chartXLabels, setXLabels] = React.useState([]);
    const [distinctProducts, setDistinctProducts] = React.useState([]);
    const [dataSeries, setDataSeries] = React.useState([]);
    const [totaVolume, setTotaVolume] = React.useState(0);

    const [chartLoading, setChartLoading] = React.useState(false);

    React.useEffect(() => {
        setChartLoading(loading)
    }, [loading]);

    // Manage Changes in view_mode
    React.useEffect(() => {
        if (view_mode === 'daily') {
            setChartTitle('Daily Fuel Sales (Liters)');
        } else if (view_mode === 'weekly') {
            setChartTitle('Weekly Fuel Sales (Liters)');
        } else if (view_mode ===  'monthly') {
            setChartTitle('Monthly Fuel Sales (Liters)');
        }
    }, [view_mode]);

    // Manage Changes in chartData
    React.useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            let data
            if (view_mode === 'daily') {
                data = chart_data.daily_sales
            }
            if (view_mode === 'weekly') {
                data = chart_data.weekly_sales
            }
            if (view_mode === 'monthly') {
                data = chart_data.monthly_sales
            }

            // const sortedData = data.map(item => {
            //     const [month, day, year] = item.date.split('-')
            //     item.date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`            
            //     return item
            // }).sort((a, b) => new Date(a.date) - new Date(b.date))
            setChartData(data)
            setDistinctProducts( chart_data.distinct_products );
        }
    }, [chart_data, chartTitle]);


    // ---------------------------------------------------------
    // -------------- TRACK CHANGES IN CHART DATA --------------
    // ---------------------------------------------------------
    React.useEffect(() => {
        // set x-labels
        setXLabels( chartData.map(item => { return item.date; }) )

        let totVolume = 0;
        chartData.forEach(item => { totVolume += item.volume });
        totVolume = Math.round( ( totVolume + Number.EPSILON ) * 100 ) / 100;
        setTotaVolume(totVolume)

        // Prepare Data
        const tempDataSeries = [];
        let counter = 1;
        for (const product of distinctProducts) {
            const productPreakdowns = chartData.map(item => {
                const productBreakdown = item.product_breakdown;

                const matchingProductBreakdownItem = productBreakdown.filter(prodItem => { return prodItem.product_code === product; })[0];
                return matchingProductBreakdownItem;
            });

            const resultSeriesItem = {
                data: productPreakdowns.map(item => { return item.total_volume; }),
                label: product,
                id: product,
                stack: 'total'
            };
            if (counter === 1) {
                resultSeriesItem.color = '#02B2AF';
            } else if (counter === 2) {
                resultSeriesItem.color = '#2E96FF';
            } else if (counter === 3) {
                resultSeriesItem.color = '#ff6600';
            } else if (counter === 4) {
                resultSeriesItem.color = '#000000';
            }
            tempDataSeries.push(resultSeriesItem);

            counter += 1;
        }

        setDataSeries(tempDataSeries);
        // setChartLoading(false)
    }, [chartData]);

    function formatNumber(value) {
        const val = Number(value / 1);
        return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(/\.00$/, '');
    }

    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea'}}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    {chartTitle} {chartLoading ? <span></span> : <><br/><span>{formatNumber(totaVolume)} L</span></>}
                </Typography>
                <Divider />
                {
                    !chartLoading ? (
                        <BarChart
                            // width={700}
                            height={600}
                            sx={{
                                marginTop:10,
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    transform: 'translate(-50px, 0)',
                                },
                            }}
                            // series={[
                            //     { data: dieselData, label: 'DIESEL50', id: 'dieselId', stack: 'total' },
                            //     { data: ulp95Data, label: 'ULP50', id: 'ulp95Id', stack: 'total' },
                            //     { data: ulp93Data, label: 'ULP03', id: 'ulp93Id', stack: 'total' },
                            // ]}
                            series={dataSeries}
                            // xAxis={[{ data: xLabels, scaleType: 'band' }]}
                            xAxis={[{ data: chartXLabels, scaleType: 'band', label: 'Date' }]}
                            yAxis={[{ label: 'Volume (L)' }]}
                        />
                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5, // Customize height
                                    marginTop: 5,
                                    backgroundColor: '#ff6600', // Background track color
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black', // Bar color
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
}

export default FuelSalesChartLiters;