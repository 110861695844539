import { useState } from "react";
import dayjs from "dayjs";


export const useDate = () => {
    const today = new Date();
    const _1st = new Date()
    _1st.setDate(1)
    
    const [startDate, setStartDate] = useState(dayjs(_1st.toISOString().split('T')[0]))
    const [endDate, setEndDate] = useState(dayjs(today.toISOString().split('T')[0]));
    return [
        {startDate, endDate},
        {setStartDate, setEndDate}
    ]
}