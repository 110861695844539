import { mkConfig, generateCsv, download } from "export-to-csv";

export const dateToYMD = (date) => {
    const d = new Date(date)
    const year = d.getFullYear()
    const month = String(d.getMonth() + 1).padStart(2, '0')
    const day = String(d.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export const exportCashDropTableToCsv = (chartTableData, startDate, endDate) => {
    const start = dateToYMD(startDate)
    const end = dateToYMD(endDate)

    const filteredData = chartTableData.map(t => ({
        Date: t.date,
        Provider: t.provider,
        Account: t.account_number,
        "Safe Id": t.safe_id,
        Amount: t.amount,
        Cashier: t.cashier,
        "Customer Ref": t.customer_ref
    }))

    const csvConfig = mkConfig({ 
        useKeysAsHeaders: true,
        filename: `cash-drops_${start}_${end}`
    })

    const csv = generateCsv(csvConfig)(filteredData)
    download(csvConfig)(csv)
}