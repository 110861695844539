import React from 'react';

import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { BarChart } from '@mui/x-charts/BarChart';
import Divider from '@mui/material/Divider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function LeftOverFuel({ chart_data, loading }) {
    const [chartTitle, setChartTitle] = React.useState('Days Remaining at The Time');
    const [chartData, setChartData] = React.useState([]);
    const [chartLoading, setChartLoading] = React.useState(false);

    React.useEffect(() => {
        setChartLoading(loading)
    }, [loading]);

    // Manage Changes in chartData
    React.useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            setChartData(chart_data.left_over_fuel);
        }
    }, [chart_data]);


    const valueFormatterFn = (value, { dataIndex }) => {
        // console.log('item1', value)
        // console.log('item2', dataIndex)
        // console.log('Chart Data Item', chartData[dataIndex])

        const { time_sast, time_left, dry_on, average_usage } = chartData[dataIndex];
        return (
            <>
            {/* <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid black', borderRadius: '5px' }}> */}
                <span><strong>Days Left:</strong> {time_left} Days</span><br/>
                <span><strong>Dry On:</strong> {dry_on.split(' ')[0]} -- at -- {dry_on.split(' ')[1]}</span><br/>
                <span><strong>Average Usage:</strong> {average_usage} Liters/Day</span><br/>
                <span><strong>Query Time:</strong> {time_sast.split(' ')[0]} -- at -- {time_sast.split(' ')[1]}</span><br/>
            {/* </div> */}
            </>
        );
    };

    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea'}}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    {chartTitle}
                </Typography>
                <Divider />
                {
                    !chartLoading ? (
                        <BarChart
                            height={500}
                            dataset={chartData}
                            yAxis={[{ scaleType: 'band', dataKey: 'product', label: 'Product' }]}
                            xAxis={[{ label: 'Days Remaining' }]}
                            series={[{ dataKey: 'time_left', color: '#ff6600', valueFormatter: valueFormatterFn }]}
                            // series={[{ dataKey: 'time_left', color: '#ff6600', label: 'Days Left To Running Dry', valueFormatter }]}
                            // series={[{ dataKey: 'time_left', color: '#ff6600', label: makeLabel }]}
                            layout="horizontal"
                            barLabel="value"
                            sx={{
                                marginTop:10,
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    transform: 'translate(-50px, 0)',
                                },
                            }}
                        />
                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5, // Customize height
                                    marginTop: 5,
                                    backgroundColor: '#ff6600', // Background track color
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black', // Bar color
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
}

export default LeftOverFuel;