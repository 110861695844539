import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Divider from '@mui/material/Divider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import LinearProgress from '@mui/material/LinearProgress';

// Register necessary components from Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement
);

function TankLevelsOverTime({ chart_data, loading }) {
    const [chartXLabels, setXLabels] = React.useState([]);
    const [dataSeries, setDataSeries] = React.useState([]);

    const [chartLoading, setChartLoading] = React.useState(false);

    React.useEffect(() => {
        setChartLoading(loading)
    }, [loading]);


    // ---------------------------------------------------------
    // -------------- TRACK CHANGES IN CHART DATA --------------
    // ---------------------------------------------------------
    React.useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            // set x-labels
            setXLabels( [...new Set(chart_data.tank_levels_over_time.map(item => { return item.time_sast; }))] );

            // Distinct Products
            const distinctProductMap = {};
            for (const dataItem of chart_data.tank_levels_over_time) {
                distinctProductMap[ dataItem.metric ] = 1;
            }


            const colorMap = {
                1: '#02B2AF',
                2: '#2E96FF',
                3: '#6E9CAD',
                4: '#D2990A',
                5: '#D20A17',
                6: '#D20AAA',
                7: '#9D47E8',
                8: '#25B700',
                9: '#F10707',
                10: '#FFFC00',
            };

            let counter = 1;
            const chartDataSeries = [];
            for (const distinctProduct of Object.keys(distinctProductMap)) {
                const fuelItemDataSeries = chart_data.tank_levels_over_time
                                                                .filter(item => { return item.metric === distinctProduct; })
                                                                .map(item => {
                                                                    return item.value
                                                                });

                const chartDataSeriesItem = {
                    // Chart JS
                    data: fuelItemDataSeries,
                    label: distinctProduct,
                    id: distinctProduct,
                    showMark: false,
                    fill: false,
                    borderColor: colorMap[counter],
                    backgroundColor: colorMap[counter],
                    borderWidth: 1,
                    pointBorderWidth: 1,
                    pointRadius: 1,

                    // // MUI
                    // data: fuelItemDataSeries,
                    // label: distinctProduct,
                    // id: distinctProduct,
                    // showMark: false,


                    // stack: 'total',
                    // color: '#02B2AF'
                };

                chartDataSeries.push(chartDataSeriesItem);

                counter += 1;
            }
            setDataSeries(chartDataSeries);

            // const safetyVolumes = [];
            // const availableVolumes = [];
            // const ullageVolumes = [];
            // for (const tankLevelItem of chart_data.tank_levels_over_time) {
            //     safetyVolumes.push(tankLevelItem.safety_volume);
            //     availableVolumes.push(tankLevelItem.available_volume);
            //     ullageVolumes.push(tankLevelItem.ullage);
            // }

            // const tempDataSeries = [];
            // const safetyVolumeItem = {
            //     data: safetyVolumes,
            //     label: 'Safety Volume',
            //     id: 'safety_volume',
            //     stack: 'total',
            //     color: '#02B2AF'
            // };
            // const availableVolumeItem = {
            //     data: availableVolumes,
            //     label: 'Available Volume',
            //     id: 'available_volume',
            //     stack: 'total',
            //     color: '#2E96FF'
            // };
            // const ullageVolumeItem = {
            //     data: ullageVolumes,
            //     label: 'Ullage',
            //     id: 'ullage',
            //     stack: 'total',
            //     color: '#ff6600'
            // };
            // tempDataSeries.push(safetyVolumeItem);
            // tempDataSeries.push(availableVolumeItem);
            // tempDataSeries.push(ullageVolumeItem);

            // setDataSeries(tempDataSeries);
        }
    }, [chart_data]);

    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea'}}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    Tank Levels Over Time
                </Typography>
                <Divider />
                {
                    !chartLoading ? (
                        // <LineChart
                        //     height={500}
                        //     sx={{
                        //         marginTop:10,
                        //         [`.${axisClasses.left} .${axisClasses.label}`]: {
                        //             transform: 'translate(-50px, 0)',
                        //         },
                        //     }}
                        //     // series={[
                        //     //     { data: dieselData, label: 'DIESEL50', id: 'dieselId', stack: 'total' },
                        //     //     { data: ulp95Data, label: 'ULP50', id: 'ulp95Id', stack: 'total' },
                        //     //     { data: ulp93Data, label: 'ULP03', id: 'ulp93Id', stack: 'total' },
                        //     // ]}
                        //     series={dataSeries}
                        //     xAxis={[{ data: chartXLabels, scaleType: 'band', label: 'Time' }]}
                        //     yAxis={[{ label: 'Volume (L)' }]}
                        // />


                        <Line 
                            data={{
                                labels: chartXLabels,
                                datasets: dataSeries
                            }}
                        />
                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5, // Customize height
                                    marginTop: 5,
                                    backgroundColor: '#ff6600', // Background track color
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black', // Bar color
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
}

export default TankLevelsOverTime;