import { useState } from 'react';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';
import { useChartTableData, useIsTableLoading } from '../hooks/useCashDropTable';
import { Button } from '@mui/material';
import { exportCashDropTableToCsv } from '../functions/utils';


const paginationModel = { page: 0, pageSize: 10 };
const columns = [
    {
        field: 'date',
        headerName: 'Date',
        flex: 1, // Equal width
    },
    {
        field: 'provider',
        headerName: 'Provider',
        // type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'account_number',
        headerName: 'Account',
        // type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'safe_id',
        headerName: 'Safe Id',
        // type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
        flex: 1, // Equal width
    },
    // {
    //     field: 'bag_number',
    //     headerName: 'Bag Number',
    //     type: 'string',
    //     flex: 1, // Equal width
    // },
    {
        field: 'cashier',
        headerName: 'Cashier',
        // type: 'number',
        flex: 1, // Equal width
    },
    {
        field: 'customer_ref',
        headerName: 'Customer Ref',
        // type: 'number',
        flex: 1, // Equal width
    },
];

function FuelSalesTable({ chart_data, isLoading, start_date, end_date }) {
    const [isTableLoading] = useIsTableLoading(isLoading, chart_data)
    const [chartTitle] = useState('Cash Drops');
    const [chartTableData] = useChartTableData(chart_data)

    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea' }}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    {chartTitle}
                </Typography>
                <Divider />
                <br/>
                {
                    !isTableLoading ? (
                        <>
                            <DataGrid
                                rows={chartTableData}
                                columns={columns}
                                getRowId={(row) => row.id}
                                initialState={{
                                    pagination: { paginationModel },
                                    sorting: {
                                        sortModel: [{ field: 'date', sort: 'desc' }],
                                    },
                                }}
                                pageSizeOptions={[10, 25, 50]}
                                sx={{
                                    border: 0,
                                    '& .MuiDataGrid-columnHeader': {
                                        backgroundColor: '#ff6600',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                    },
                                    '& .MuiDataGrid-row': {
                                        backgroundColor: '#ffffff',
                                    },
                                }}
                            />
                            <Button
                                sx={{
                                    background: '#ff6600',
                                    color: 'white'
                                }}
                                onClick={() => exportCashDropTableToCsv(chartTableData, start_date, end_date)}
                            >
                                download csv
                            </Button>
                        </>

                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5,
                                    marginTop: 5,
                                    backgroundColor: '#ff6600',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black',
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
}

export default FuelSalesTable;