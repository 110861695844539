import { gql } from "@apollo/client";

export const GET_SITES = gql`
    query {
        sites {
            id
            name
            code
            limit
            new_id
        }
    }
`;