import { gql } from "@apollo/client";

export const GET_FUEL_INVENTORY = gql`
    query fuelInventory($siteId: String!, $dateTime: String!) {
        fuelInventory(siteId: $siteId, dateTime: $dateTime) {
            timestamp
            tank_levels_by_product {
                product_code
                safety_volume
                available_volume
                ullage_volume
            }
            tank_levels_by_tank {
                tank_number
                product_code
                safety_volume
                available_volume
                ullage_volume
            }
            left_over_fuel {
                time_sast
                # days_ago
                product
                available
                average_usage
                time_left
                dry_on
            }
            upcoming_invoices {
                id
                site_id
                delivery_date
                product_code
                value
                product
                volume
                net_unit_price
                invoice_number
                invoice_date
                invoice_volume
                invoice_unit_price
                invoice_value
                order_number
                order_date
                order_volume
                order_unit_price
                order_status
                order_status_at
                order_value
                delivered_at
                delivered_volume
                requested_delivery_date
            }
            tank_levels_over_time {
                time_sast
                metric
                value
            }
        }
    }
`;