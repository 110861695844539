import React from 'react';

import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Divider from '@mui/material/Divider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import LinearProgress from '@mui/material/LinearProgress';

// const ulp93Data = [2400, 1398, 3100, 3908, 4800, 3800, 4300];
// const ulp95Data = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
// const dieselData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
// const xLabels = [
//     'Page A',
//     'Page B',
//     'Page C',
//     'Page D',
//     'Page E',
//     'Page F',
//     'Page G',
// ];

function FuelSalesChart({ chart_data, loading }) {
    const [chartXLabels, setXLabels] = React.useState([]);
    const [dataSeries, setDataSeries] = React.useState([]);
    const [graphTotals, setGraphTotals] = React.useState([]);

    const [chartLoading, setChartLoading] = React.useState(false);

    React.useEffect(() => {
        setChartLoading(loading)
    }, [loading]);


    // ---------------------------------------------------------
    // -------------- TRACK CHANGES IN CHART DATA --------------
    // ---------------------------------------------------------
    React.useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            // set x-labels
            const xLabels = [];
            for (const tankLevelItem of chart_data.tank_levels_by_tank) {
                xLabels.push(tankLevelItem.tank_number + ' - ' + tankLevelItem.product_code);
            }
            setXLabels( xLabels )

            const safetyVolumes = [];
            const availableVolumes = [];
            const ullageVolumes = [];
            const totals = [];
            for (const tankLevelItem of chart_data.tank_levels_by_tank) {
                safetyVolumes.push(tankLevelItem.safety_volume);
                availableVolumes.push(tankLevelItem.available_volume);
                ullageVolumes.push(tankLevelItem.ullage_volume);

                const total = tankLevelItem.safety_volume + tankLevelItem.available_volume + tankLevelItem.ullage_volume;
                totals.push(total);
            }
            setGraphTotals(totals);

            const tempDataSeries = [];
            const safetyVolumeItem = {
                data: safetyVolumes,
                label: 'Safety Volume',
                id: 'safety_volume',
                stack: 'total',
                color: '#02B2AF'
            };
            const availableVolumeItem = {
                data: availableVolumes,
                label: 'Available Volume',
                id: 'available_volume',
                stack: 'total',
                color: '#2E96FF'
            };
            const ullageVolumeItem = {
                data: ullageVolumes,
                label: 'Ullage',
                id: 'ullage',
                stack: 'total',
                color: '#ff6600'
            };
            tempDataSeries.push(safetyVolumeItem);
            tempDataSeries.push(availableVolumeItem);
            tempDataSeries.push(ullageVolumeItem);

            setDataSeries(tempDataSeries);
        }
    }, [chart_data]);

    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea'}}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    Tank Levels (By Tank)
                </Typography>
                <Divider />
                {
                    !chartLoading ? (
                        <BarChart
                            // width={700}
                            height={500}
                            sx={{
                                marginTop:10,
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    transform: 'translate(-50px, 0)',
                                },
                            }}
                            // series={[
                            //     { data: dieselData, label: 'DIESEL50', id: 'dieselId', stack: 'total' },
                            //     { data: ulp95Data, label: 'ULP50', id: 'ulp95Id', stack: 'total' },
                            //     { data: ulp93Data, label: 'ULP03', id: 'ulp93Id', stack: 'total' },
                            // ]}
                            series={dataSeries}
                            barLabel="value"
                            // xAxis={[{ data: xLabels, scaleType: 'band' }]}
                            xAxis={[{ data: chartXLabels, scaleType: 'band', label: 'Tanks' }]}
                            yAxis={[{ label: 'Volume (L)' }]}
                        >
                            {/* Display total values */}
                            {/* {graphTotals.map((entry, index) => (
                                <text
                                    key={`total-${index}`}
                                    x={130 + index * (500 / graphTotals.length)} // Adjust position to match bar location
                                    y={85} // Adjust based on the Y-axis scale
                                    fill="black"
                                    textAnchor="middle"
                                    fontSize={14}
                                    >
                                    {entry}
                                </text>
                            ))} */}
                        </BarChart>
                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5, // Customize height
                                    marginTop: 5,
                                    backgroundColor: '#ff6600', // Background track color
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black', // Bar color
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
}

export default FuelSalesChart;