import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register necessary components from Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement
);

const options = {
    responsive: true,
    scales: {
        x: {
            stacked: true, // Stack bars on the x-axis
        },
        y: {
            stacked: true, // Stack bars on the y-axis
        },
    },
    plugins: {
        title: {
            display: true,
            text: 'Test Title',
            font: {
                size: 24,
                // style: 'italic',
                // family: 'Helvetica Neue'
            }
        },
    }
};

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            type: 'line', // Line dataset
            label: 'Line',
            data: [12, 28, 42, 90, 70, 85, 100],
            // borderColor: 'rgba(255, 99, 132, 1)',
            // backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: '#000000',
            backgroundColor: '#000000',
            fill: false,
        },
        {
            type: 'bar', // Stacked bar dataset 1
            label: 'Bar 1',
            data: [10, 20, 30, 40, 50, 60, 70],
            // backgroundColor: 'rgba(75, 192, 192, 0.6)',
            // backgroundColor: '#02B2AF',
            backgroundColor: '#ff6600',
            // stack: 'Stack 0',
        },
        {
            type: 'bar', // Stacked bar dataset 1
            label: 'Bar 2',
            data: [10, 20, 30, 40, 50, 60, 70],
            // backgroundColor: 'rgba(75, 192, 192, 0.6)',
            // backgroundColor: '#02B2AF',
            backgroundColor: 'green',
            stack: 'Stack 0',
        },
        {
            type: 'bar', // Stacked bar dataset 2
            label: 'Bar 3',
            data: [5, 15, 25, 35, 45, 55, 65],
            // backgroundColor: 'rgba(153, 102, 255, 0.6)',
            backgroundColor: '#2E96FF',
            stack: 'Stack 0',
        },
        // {
        //     type: 'line', // Line dataset
        //     label: 'Line',
        //     data: [12, 28, 42, 90, 70, 85, 100],
        //     // borderColor: 'rgba(255, 99, 132, 1)',
        //     // backgroundColor: 'rgba(255, 99, 132, 0.2)',
        //     borderColor: '#000000',
        //     backgroundColor: '#000000',
        //     fill: false,
        // },
    ],
};

const MixedChart = () => {
  return <Bar options={options} data={data} />;
};

export default MixedChart;
