import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

export const LoadingSites = () => 
    <Grid container spacing={8} sx={{ marginTop:15 }}>
        <Grid size={10} offset={{ sm: 2 }}>
            <Box sx={{ width: '100%', height: '600px' }}>
                <Typography component="div" variant="h4" align="center">
                    Loading Sites...
                </Typography>
                <LinearProgress
                    sx={{
                        height: 5, // Customize height
                        marginTop: 5,
                        backgroundColor: '#ff6600', // Background track color
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'black', // Bar color
                        },
                    }}
                />
            </Box>
        </Grid>
    </Grid>