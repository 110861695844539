import { gql } from "@apollo/client";

export const LOGIN = gql`
	mutation($input: LoginInput!) {
		authLogin(input: $input) {
			token
			message
			duration
		}
	}
`;